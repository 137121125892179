import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import RightArrow from "../assets/icons/chevron-right.svg";
import Contact from "../components/contact";
import Header from "../components/Header";
import Layout from "../components/layout";
import PortfolioAnime from "../lotties/portfolio";
import styles from "../styles/portfolio.module.scss";

const portfolioAnimation = {
  loop: true,
  autoplay: true,
  animationData: PortfolioAnime,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Portfolio = () => {
  // const [activePortfolio, updateActive] = useState("industries");

  // const changeIcons = (event) => {
  //   const currentSelection = event.target;
  //   const portfolioSelected = currentSelection.innerHTML.toLowerCase();

  //   if (activePortfolio !== portfolioSelected) {
  //     const portfolioItems = currentSelection.parentElement.children;
  //     const items = Array.from(portfolioItems);
  //     updateActive(portfolioSelected);
  //     console.log(portfolioItems);
  //     items.map((item) => {
  //       const itemName = item.innerHTML.toLowerCase();
  //       if (itemName === portfolioSelected) {
  //         document.getElementById(itemName).classList.remove(styles.remove);
  //         return item.classList.add(styles.active);
  //       } else {
  //         document.getElementById(itemName).classList.add(styles.remove);
  //       }
  //       return item.classList.remove(styles.active);
  //     });
  //   }
  //   console.log(`current selection: ${portfolioSelected}`);
  // };

  const data = useStaticQuery(graphql`
    query {
      industryIcons: allFile(
        filter: { relativeDirectory: { eq: "assets/icons/industry" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
      serviceIcons: allFile(
        filter: { relativeDirectory: { eq: "assets/icons/service" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `);

  let serviceIcons = data.serviceIcons.edges.slice();
  let paginatedServiceIcons = [];
  let industryIcons = data.industryIcons.edges.slice();
  let paginatedIndustryIcons = [];

  // add placeholder columns to service icons retain container height
  if (serviceIcons.length % 6 !== 0) {
    for (let i = 0; i < serviceIcons.length % 6; i++) {
      let col = {};
      col = { ...data.serviceIcons.edges[0].node };
      col = { node: col };
      col.node.name = "";
      col.node.publicURL = "";
      serviceIcons.push(col);
    }
  }
  // add placeholder columns to industry icons retain container height
  if (industryIcons.length % 6 !== 0) {
    for (let i = 0; i < industryIcons.length % 6; i++) {
      let col = {};
      col = { ...data.industryIcons.edges[0].node };
      col = { node: col };
      col.node.name = "";
      col.node.publicURL = "";
      industryIcons.push(col);
      console.log(`adding empty cols: ${i}`);
      console.log(col);
    }
  }

  // make service icons paginated
  while (serviceIcons.length) {
    paginatedServiceIcons.push(serviceIcons.splice(0, 3));
  }
  while (paginatedServiceIcons.length) {
    serviceIcons.push(paginatedServiceIcons.splice(0, 2));
  }

  // make industry icons paginated
  while (industryIcons.length) {
    paginatedIndustryIcons.push(industryIcons.splice(0, 3));
  }
  while (paginatedIndustryIcons.length) {
    industryIcons.push(paginatedIndustryIcons.splice(0, 2));
  }

  // console.log("check page format");
  // console.log(industryIcons[industryIcons.length - 2]);

  // if (industryIcons[industryIcons.length - 1].length < 2) {
  //   let row = [];
  //   let col = {};
  //   for (let key in data.industryIcons.edges[0]) {
  //     col[key] = data.industryIcons.edges[0][key];
  //   }
  //   col.node.name = "";
  //   col.node.publicURL = "";
  //   console.log("added column");
  //   console.log(col);
  //   row.push(col);
  //   console.log("added row");
  //   console.log(row);
  //   industryIcons[industryIcons.length - 1].push(row);
  // }
  // console.log(industryIcons[industryIcons.length - 1]);
  // console.log("----------------------");

  const nextIcon = (event) => {
    let iconsSet = [];
    const buttonSelection = event.target.id.split("_");

    if (buttonSelection[0] === "service") {
      iconsSet = document.getElementsByClassName(styles.servicesList);
    } else if (buttonSelection[0] === "industry") {
      iconsSet = document.getElementsByClassName(styles.industriesList);
    } else {
      return;
    }

    iconsSet = Array.from(iconsSet);

    for (let i = 0; i < iconsSet.length; i++) {
      if (iconsSet[i].classList.contains(styles.show)) {
        iconsSet[i].classList.toggle(styles.show);
        if (i === iconsSet.length - 1) {
          iconsSet[0].classList.toggle(styles.show);
        } else {
          iconsSet[i + 1].classList.toggle(styles.show);
        }
        break;
      }
    }
  };

  const prevIcon = (event) => {
    let iconsSet = [];
    const buttonSelection = event.target.id.split("_");

    if (buttonSelection[0] === "service") {
      iconsSet = document.getElementsByClassName(styles.servicesList);
    } else if (buttonSelection[0] === "industry") {
      iconsSet = document.getElementsByClassName(styles.industriesList);
    } else {
      return;
    }

    iconsSet = Array.from(iconsSet);

    for (let i = 0; i < iconsSet.length; i++) {
      if (iconsSet[i].classList.contains(styles.show)) {
        iconsSet[i].classList.toggle(styles.show);
        if (i === 0) {
          iconsSet[iconsSet.length - 1].classList.toggle(styles.show);
        } else {
          iconsSet[i - 1].classList.toggle(styles.show);
        }
        break;
      }
    }
  };

  // to focus the div element containing the contents
  const ready = () => {
    document.getElementsByClassName(styles.portfolio)[0].focus();
  };

  useEffect(() => {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", ready);
    } else {
      ready();
    }
  }, []);

  return (
    <Layout
      title="Portfolio | Mahusai"
      description="We have been building products for years and have worked with many clients in various industries, big and small."
      pathname="/portfolio/"
    >
      <div
        className={styles.portfolio}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <Header />

        <Container fluid>
          <Row xs={1} className={styles.row}>
            <Col xs md lg={{ span: 7 }} className="col-height-mobile">
              <div className="details-section center-middle-position">
                <div className="title">Portfolio</div>
                <div className="description">
                  <p>
                    We have been building products for years and have worked
                    with many clients in various industries, big and small.
                  </p>
                  <p>
                    We provide solutions by understanding the people interacting
                    with it. From design to development to support, we keep user
                    experience in mind.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 5 }}>
              <div className={styles.centerMiddle}>
                <Lottie options={portfolioAnimation} height="inherit" />
              </div>
            </Col>
          </Row>
        </Container>

        <div className={styles.portfolioList}>
          <Container fluid className={styles.containerHeight}>
            <h1 className={styles.portfolioTitle}>Industries</h1>
            {industryIcons.map((page, i) => {
              return (
                <Row
                  key={`row_${i}`}
                  xs={2}
                  md={2}
                  lg={3}
                  className={`${styles.industriesList} ${styles.space} ${
                    i === 0 ? styles.show : ""
                  }`}
                >
                  {page.map((row) => {
                    return row.map((icon, k) => {
                      return (
                        <Col
                          key={`col_${k}`}
                          md
                          lg
                          className={styles.colHeight}
                        >
                          <div key={`div_${k}`} className={styles.icon}>
                            <img
                              key={`img_${k}`}
                              src={icon.node.publicURL}
                              alt={icon.node.name}
                            />
                            <span key={`span_${i}`} className={styles.iconName}>
                              {icon.node.name.split("-").slice(1).join(" ")}
                            </span>
                          </div>
                        </Col>
                      );
                    });
                  })}
                </Row>
              );
            })}
            <Row xs={2}>
              <Col md className={styles.prevButton}>
                <button id="industry_prev" onClick={prevIcon}>
                  <RightArrow className={styles.arrow} /> PREVIOUS
                </button>
              </Col>
              <Col md className={`${styles.nextButton} text-right`}>
                <button id="industry_next" onClick={nextIcon}>
                  NEXT <RightArrow className={styles.arrow} />
                </button>
              </Col>
            </Row>
          </Container>

          <Container fluid className={styles.containerHeight}>
            <h1 className={styles.portfolioTitle}>Solutions</h1>
            {serviceIcons.map((page, i) => {
              return (
                <Row
                  key={`row_${i}`}
                  xs={2}
                  md={2}
                  lg={3}
                  className={`${styles.servicesList} ${styles.space} ${
                    i === 0 ? styles.show : ""
                  }`}
                >
                  {page.map((row) => {
                    return row.map((icon, k) => {
                      return (
                        <Col
                          key={`col_${k}`}
                          md
                          lg
                          className={styles.colHeight}
                        >
                          <div key={`div_${k}`} className={styles.icon}>
                            <img
                              key={`img_${k}`}
                              src={icon.node.publicURL}
                              alt={icon.node.name}
                            />
                            <span key={`span_${i}`} className={styles.iconName}>
                              {icon.node.name.split("_").slice(1).join(" ")}
                            </span>
                          </div>
                        </Col>
                      );
                    });
                  })}
                </Row>
              );
            })}
            <Row xs={2}>
              <Col md className={styles.prevButton}>
                <button id="service_prev" onClick={prevIcon}>
                  <RightArrow className={styles.arrow} /> PREVIOUS
                </button>
              </Col>
              <Col md className={`${styles.nextButton} text-right`}>
                <button id="service_next" onClick={nextIcon}>
                  NEXT <RightArrow className={styles.arrow} />
                </button>
              </Col>
            </Row>
          </Container>
        </div>

        <Contact
          bgColorLeft="#002E5E"
          fontColorLeft="#FDFEFD"
          bgColorRight="#F1F1F2"
          fontColorRight="#002E5E"
          linkColor="#C3E4F6"
          linkBlue={true}
        />
      </div>
    </Layout>
  );
};

export default Portfolio;
